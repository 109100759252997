<template>
  <ui-expand
    class="group-attendance-item"
    position="fixed"
    full-width
    contents-class="expanded-contents"
    toolbar-class="expanded-toolbar"
  >
    <template #trigger>
      <ui-item
        icon="g:assignment_ind"
        :text="text"
      ></ui-item>
    </template>

    <template #header>
      <ui-item
        class="header-item"
        :text="text"
      >
        <template #right>
          <session-picker
            class="attendance-session-picker"
            :group-id="groupId"
            v-model="sessionId"
            :date.sync="date"
            auto-select
          ></session-picker>

          <div
            class="attendance-notice"
            v-if="helper"
            @click="helper.showPending()"
          >
            <span
              class="ui-badge"
              v-if="helper.pending.length"
              v-text="helper.pending.length"
            ></span>
            <ui-icon
              value="mdi:wifi"
              size="20"
            ></ui-icon>
          </div>
        </template>
      </ui-item>
    </template>

    <template #contents>
      <v3-attendance
        v-if="sessionId"
        context="session"
        :context-id="sessionId"
        :date="date"
        @update:helper="helper = $event"
      ></v3-attendance>
      <ui-empty v-else>
        <h3 style="text-align:center">{{ date.toLocaleDateString() }}</h3>
        <p>No hay clases este día</p>
      </ui-empty>
    </template>
  </ui-expand>
</template>

<script>
import { UiExpand, UiItem, UiIcon, UiEmpty } from "@/modules/ui/components";
import SessionPicker from "@/modules/v3/components/V3SessionPicker/V3SessionPicker.vue";
import V3Attendance from "@/modules/v3/components/V3Attendance/V3Attendance.vue";

export default {
  name: "v3-teacher-dashboard-group-attendance",

  components: {
    UiExpand,
    UiItem,
    UiIcon,
    UiEmpty,
    SessionPicker,
    V3Attendance
  },

  props: {
    groupId: {
      required: true
    },

    text: {
      type: String,
      required: false,
      default: "Asistencia"
    }
  },

  data() {
    return {
      date: new Date(),
      sessionId: null,
      helper: null
    };
  }
};
</script>

<style lang="scss">
.group-attendance-item {
  .attendance-notice {
    position: relative;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;

    .ui-badge {
      background-color: var(--ui-color-danger);
      color: #fff;

      position: absolute;
      top: 12px;
      right: 4px;

      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 8px;
      font-weight: bold;
      width: 16px;
      height: 16px;
      line-height: 16px;
    }
  }

  .header-item {
    min-height: 48px;
  }

  .attendance-session-picker {
    border: 0;
    background-color: transparent;
  }

  .expanded-contents {
    background-color: #fff !important;

    .ui-tabs {
      position: sticky;
      top: 48px;
      z-index: 2;

      background-color: var(--ui-color-primary);
      color: #fff;

      .ui-tab-item.selected label {
        color: #fff;
        opacity: 1;
      }

      // tab bottom border
      .ui-tab .ui-tab-item::after {
        background-color: #fff;
        height: 3px;
      }
    }
  }

  .expanded-toolbar {
    background-color: var(--ui-color-primary) !important;
    color: #fff;

    .toolbar-arrow {
      color: #fff !important;
    }
  }
}
</style>