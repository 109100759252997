<template>
  <div class="v3-teacher-dashboard">
    <ui-loading v-if="isLoading"></ui-loading>

    <template v-else>
      <ui-list>
        <template v-if="classItems.length">
          <p class="ui-subheader">{{ $t('V3TeacherDashboard.Sections') }}</p>

          <div class="ui-card">
            <ui-drawer
              v-for="item in classItems"
              :key="item.id"
              drawer-group="page-teaching-items"
            >
              <template #trigger>
                <ui-item v-bind="item"></ui-item>
              </template>

              <template #contents>
                <ui-list class="--sublist">
                  <section-attendance-item
                    :section-id="item.id"
                    :text="$t('V3TeacherDashboard.attendance.section.text')"
                  ></section-attendance-item>
                </ui-list>
              </template>
            </ui-drawer>
          </div>
        </template>

        <template v-if="groupItems.length">
          <p class="ui-subheader">{{ $t('V3TeacherDashboard.Subjects') }}</p>

          <div class="ui-card">
            <ui-drawer
              v-for="item in groupItems"
              :key="item.id"
              drawer-group="page-teaching-items"
            >
              <template #trigger>
                <ui-item v-bind="item"></ui-item>
              </template>
              <template #contents>
                <ui-list class="--sublist">
                  <slot
                    name="academic-group-item"
                    :group="item"
                  ></slot>

                  <group-attendance-item
                    :group-id="item.id"
                    :text="$t('V3TeacherDashboard.attendance.group.text')"
                  ></group-attendance-item>
                </ui-list>
              </template>
            </ui-drawer>
          </div>
        </template>
      </ui-list>
    </template>
  </div>
</template>

<script>
import useI18n from '@/modules/i18n/mixins/useI18n.js';
import useApi from "@/modules/api/mixins/useApi";
import academicApi from "@/modules/v3/api/academic";

import { UiList, UiItem, UiDrawer, UiLoading } from "@/modules/ui/components";

// Itemized modules
import SectionAttendanceItem from "./SectionAttendanceItem.vue";
import GroupAttendanceItem from "./GroupAttendanceItem.vue";

export default {
  name: "v3-teacher-dashboard",
  mixins: [useApi, useI18n],
  api: academicApi,

  components: {
    UiList,
    UiItem,
    UiDrawer,
    UiLoading,

    SectionAttendanceItem,
    GroupAttendanceItem
  },

  props: {
    personId: {
      type: String,
      required: true
    },

    yearId: {
      type: String,
      required: false,
      default: null
    }
  },

  data() {
    return {
      nodes: [],
      isLoading: false
    };
  },

  computed: {
    classItems() {
      return this.nodes
        .filter(
          n =>
            n.type == "section" &&
            n.people.length &&
            n.people[0].id == this.personId
        )
        .map(n => ({
          id: n.id.substring(1), // El id del nodo viene como "s[SECTION ID]"
          text: n.name,
          icon: "g:group"
        }));
    },

    groupItems() {
      return this.nodes
        .filter(n => n.type == "group")
        .map(n => {
          let parts = n.name.split(" - ");
          return {
            id: n.id.substring(1), // El id del nodo viene como "g[GROUP ID]"
            text: parts[0],
            secondary: parts[1],
            icon: "g:class"
          };
        });
    }
  },

  watch: {
    personId() {
      this.fetchNodes();
    },

    yearId() {
      this.fetchNodes();
    }
  },

  methods: {
    async fetchNodes() {
      let storageKey = `teacher:dashboard:${this.personId}:${this.yearId}`;
      let stored = JSON.parse(localStorage.getItem(storageKey));
      if (stored) {
        this.nodes = stored;
      }

      this.isLoading = true;
      try {
        this.nodes = await this.$api.getTeacherNodes(this.personId, this.yearId);
        localStorage.setItem(storageKey, JSON.stringify(this.nodes));
      } catch (err) {
        // zzzzzzz
      }
      this.isLoading = false;
    },

    fadeBeforeEnter(el) {
      let sourceItem = document.getElementById("fooitem");
      let bottom =
        sourceItem.offsetParent.offsetHeight -
        (sourceItem.offsetTop + sourceItem.offsetHeight);

      el.style.setProperty("--foo-top", sourceItem.offsetTop + "px");
      el.style.setProperty("--foo-bottom", bottom + "px");
    }
  },

  mounted() {
    this.fetchNodes();
  },

  i18n: {
    es: {
      "V3TeacherDashboard.Sections": "Dirección de grupo",
      "V3TeacherDashboard.Subjects": "Clases",
      "V3TeacherDashboard.attendance.section.text": "Asistencia",
      "V3TeacherDashboard.attendance.group.text": "Asistencia"
    },

    en: {
      "V3TeacherDashboard.Sections": "Group direction",
      "V3TeacherDashboard.Subjects": "Subjects",
      "V3TeacherDashboard.attendance.section.text": "Attendance",
      "V3TeacherDashboard.attendance.group.text": "Attendance"
    }
  }
};
</script>

<style lang="scss">
.v3-teacher-dashboard {
  .popup {
    background-color: #fff;
  }

  .popup-contents {
    padding: 32px;
  }
}
</style>