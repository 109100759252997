export default httpClient => ({

  /* Estos deberian estar en el api de enrollments (!) */
  getSectionEnrollments(sectionId) {
    return httpClient.get(`/v3/course/sections/${sectionId}/enrollments`);
  },

  getSessionInscriptions(sessionId) {
    return httpClient.get(`/v3/academic/sessions/${sessionId}/inscriptions`);
  },
  /* / */

  /*
  getPeople("section", 123);
  getPeople("session", 123);
  */
  async getPeople(contextName, contextId) {
    if (contextName == "section") {
      let enrollments = await this.getSectionEnrollments(contextId);

      return enrollments.map(enrollment => ({
        id: enrollment.person.id,
        gender: enrollment.person.gender,
        firstName: enrollment.person.firstname,
        lastName: enrollment.person.lastname1,
        lastName2: enrollment.person.lastname2,
        avatar: enrollment.person.avatar
      }));

    } else {
      let inscriptions = await this.getSessionInscriptions(contextId);

      return inscriptions.map(person => ({
        id: person.id,
        gender: person.gender,
        firstName: person.firstname,
        lastName: person.lastname1,
        lastName2: person.lastname2,
        avatar: person.avatar
      }));

    }
  },

  getAbsences(contextName, contextId, query) {
    if (typeof query.date == "object") {
      query.date = query.date.getDate() + "-" + (query.date.getMonth() + 1) + "-" + query.date.getFullYear();
    }

    if ( contextName == "section") {
      return httpClient.get(`/v3/attendance/absences/sections/${contextId}`, query);
    } else {
      return httpClient.get(`/v3/attendance/absences/sessions/${contextId}`, query);
    }
  },


  /*
  getAbsenceTypes("section")
  getAbsenceTypes("session")
  */
  getAbsenceTypes(context) {
    return httpClient.get('/v3/attendance/absencetypes', { context: context == "section" ? 1 : 2 });
  },

  getAbsenceConsolidate(date) {
    return httpClient.get('/v3/attendance/consolidate', { date });
  },

  getSessionAbsences(sessionId, query) {
    if (typeof query.date == "object") {
      query.date = query.date.getDate() + "-" + (query.date.getMonth() + 1) + "-" + query.date.getFullYear();
    }

    return httpClient.get(`/v3/attendance/absences/sessions/${sessionId}`, query);
  },

  getSectionAbsences(sectionId, query) {
    if (typeof query.date == "object") {
      query.date = query.date.getDate() + "-" + (query.date.getMonth() + 1) + "-" + query.date.getFullYear();
    }

    return httpClient.get(`/v3/attendance/absences/sections/${sectionId}`, query);
  },

  createSessionAbsence(sessionId, absenceData) {
    return httpClient.post(`/v3/attendance/absences/sessions/${sessionId}`, absenceData);
  },

  createSectionAbsence(sectionId, absenceData) {
    return httpClient.post(`/v3/attendance/absences/sections/${sectionId}`, absenceData);
  },

  updateAbsence(absenceId, absenceData) {
    return httpClient.put(`/v3/attendance/absences/${absenceId}`, absenceData);
  },

  deleteAbsence(absenceId) {
    return httpClient.delete(`/v3/attendance/absences/${absenceId}`);
  },


  /*
  Operacion en BULK.
  Para el arreglo de Attendance/Entities se ejecutan las acciones especificadas segun las propiedades:
  _create
  _delete

  */
  bulk(absences) {
    return httpClient.post(`/v3/attendance/absences`, absences);
  }

})