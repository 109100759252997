<template>
  <phi-page
    xxxx-loading="$store.state.api.isLoading"
    color="#f2f2f2"
  >
    <div slot="toolbar">
      <mu-icon-button
        @click="$router.go(-1)"
        icon="arrow_back"
      />

      <h1>{{ $t("launcher.teaching") }}</h1>

      <year-picker
        class="teacher-year-picker"
        v-model="year"
        auto-select
      ></year-picker>
    </div>

    <teacher-dashboard
      v-if="year && year.id"
      :yearId="year.id"
      :personId="personId"
    ></teacher-dashboard>
  </phi-page>
</template>

<script>
import YearPicker from "@/modules/v3/components/V3YearPicker/V3YearPicker.vue";
import TeacherDashboard from "@/modules/v3/components/V3TeacherDashboard/V3TeacherDashboard.vue";

export default {
  components: {
    YearPicker,
    TeacherDashboard
  },

  data() {
    return {
      year: null,
      personId: this.$route.params.personId
    };
  }
};
</script>

<style lang="scss">
.teacher-year-picker {
  border: 0;
  background: transparent;
}

.expanded-toolbar
{
	z-index: 3;
}
</style>