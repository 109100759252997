<template>
  <div class="v3-academic-session-picker">
    <slot
      name="trigger"
      :toggle="toggle"
    >
      <p
        class="session-label"
        @click="isOpen = !isOpen"
        v-text="label"
      ></p>
    </slot>

    <ui-dialog
      :open.sync="isOpen"
      :actions="dialogActions"
    >
      <template #contents>
        <div class="session-dialog-contents">
          <div class="session-date-picker">
            <ui-input-date v-model="innerDate"></ui-input-date>
          </div>

          <ui-list
            class="session-list"
            v-show="filteredSessions.length"
          >
            <ui-item
              v-for="session in filteredSessions"
              :key="session.id"
              class="session-list-item"
              @click="selectSession(session)"
              icon="g:access_time"
              :text="session.start_time + ' - ' + session.end_time"
              :secondary="session.period.name"
            ></ui-item>
          </ui-list>

          <div v-show="!filteredSessions.length">No hay clases configuradas para este dia</div>
        </div>
      </template>
    </ui-dialog>
  </div>
</template>

<script>
import useApi from "@/modules/api/mixins/useApi";
import academicApi from "@/modules/v3/api/academic";

import { UiDialog, UiList, UiItem, UiInputDate } from "@/modules/ui/components";

export default {
  name: "v3-academic-session-picker",

  mixins: [useApi],
  api: academicApi,

  components: {
    UiDialog,
    UiList,
    UiItem,
    UiInputDate
  },

  props: {
    groupId: {
      required: true
    },

    value: {
      required: false,
      default: null
    },

    date: {
      required: false,
      default: () => new Date()
    },

    autoSelect: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      innerDate: null,
      sessionId: null,

      isOpen: false,
      sessions: [],

      dialogActions: [
        {
          label: "Cancel",
          action: () => (this.isOpen = false)
        }
      ]
    };
  },

  computed: {
    label() {
      if (!this.sessionId) {
        return this.date.toLocaleDateString();
      }

      let sessionObject = this.sessions.find(sess => sess.id == this.sessionId);
      if (!sessionObject) {
        return "ERROR";
      }

      return this.date.toLocaleDateString() + " " + sessionObject.start_time;
    },

    filteredSessions() {
      if (!this.sessions.length) {
        return [];
      }

      let timestamp = Math.floor(this.innerDate.getTime() / 1000);
      let curDay = this.innerDate.getDay();
      return this.sessions.filter(
        sess =>
          sess.weekday == curDay &&
          sess.period.start_date < timestamp &&
          timestamp < sess.period.end_date
      );
    }
  },

  methods: {
    async fetchSessions() {
      this.sessions = await this.$api.getGroupSessions(this.groupId);
    },

    selectSession(session) {
      this.sessionId = session.id;

      this.$emit("input", this.sessionId);
      this.$emit("update:date", this.innerDate);
      this.isOpen = false;
    },

    toggle() {
      this.isOpen = !this.isOpen;
    }
  },

  watch: {
    groupId: {
      immediate: true,
      async handler() {
        await this.fetchSessions();
        if (this.autoSelect && this.filteredSessions.length) {
          this.selectSession(this.filteredSessions[0]);
        }
      }
    },

    value: {
      immediate: true,
      handler(newValue) {
        this.sessionId = newValue;
      }
    },

    date: {
      immediate: true,
      handler(newValue) {
        this.innerDate = new Date(newValue);
      }
    }
  }
};
</script>

<style lang="scss">
.session-dialog-contents {
  min-height: 300px;
}

.session-label {
  cursor: pointer;
  font-size: 0.8em;
}

.session-date-picker {
  margin: 8px 0 24px 0;
}

.session-list-item {
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
  }
}
</style>